import CashBook from "../pages/Cashbook/Cashbook";
import CatalogStock from "../pages/Catalog/CatalogStock";
import ViewCatalog from "../pages/Catalog/ViewCatalog";
import Dashboard from "../pages/Dashboard";
import DirectFinishedOrder from "../pages/DirectPurchaseOrder/DirectFinishedOrder";
import DirectUnfinishedEmbroidery from "../pages/DirectPurchaseOrder/DirectUnfinishedEmbroidery";
import DirectUnfinishedOrder from "../pages/DirectPurchaseOrder/DirectUnfinishedOrder";
import ViewDirectProductPO from "../pages/DirectPurchaseOrder/VIewDirectProductPO";
import ExpenseCategory from "../pages/ExpenseCategory/ExpenseCategory";
import DirectPurchaseOrder from "../pages/Grey/DirectPurchaseOrder";
import Return from "../pages/In/Return";
import AddLocation from "../pages/Location/AddLocation";
import AssignLocation from "../pages/Location/AssignLocation";
import ViewLocation from "../pages/Location/ViewLocation";
import ViewProduct from "../pages/Product/ViewProduct";
import { CashReport } from "../pages/Reports/CashReport";
import { CreditReport } from "../pages/Reports/CreditReport";
import { ProductSalesReport } from "../pages/Reports/ProductSalesReport";
import { ReturnReport } from "../pages/Reports/ReturnReport";
import { SalesReport } from "../pages/Reports/SalesReport";
import StockAdjustment from "../pages/StockAdjustment/StockAdjustment";
import ViewStockAdjustment from "../pages/StockAdjustment/ViewStockAdjustment";
import StockTransfer from "../pages/StockTransfer/StockTransfer";
import ViewStockTransfer from "../pages/StockTransfer/ViewStockTransfer";
import AddCustomer from "../pages/Users/AddCustomer";
import AddEmployee from "../pages/Users/AddEmployee";
import AddVendor from "../pages/Users/AddVendor";
import ADMIN_ROLES from "./roles";
import ViewVendors from "../pages/Users/ViewVendors";
import ViewCustomers from "../pages/Users/ViewCustomers";
import CustomerDetail from "../pages/Users/CustomerDetail";
import EditUser from "../pages/Users/EditUser";
import Sale from "../pages/Sale/Sale";
import ViewSale from "../pages/Sale/ViewSale";
import GenBarcode from "../pages/Barcode/GenBarcode";
import ViewOutStockTransfer from "../pages/StockTransfer/ViewOutStockTransfer";
import ViewCashbook from "../pages/Cashbook/ViewCashbook";
import ViewBankBook from "../pages/Cashbook/ViewBankBook";
import LowStock from "../pages/Notification/LowStock";
import IncompletePurchase from "../pages/Notification/IncompletePurchase";
import InventoryFilter from "../pages/InventoryFilter";
import UnfinishedTransfer from "../pages/PurchaseOrders/UnfinishedTransfer";
import ViewUnfinishedTransfer from "../pages/PurchaseOrders/ViewUnfinishedTransfer";
import GreyInventoryInternal from "../pages/Grey/GreyInventoryInternal";
import AddGrey from "../pages/Grey/AddGrey";
import AddGreyColor from "../pages/Grey/AddGreyColor";
import ViewPrintOrder from "../pages/PrintPurchaseOrder/ViewPrintOrder";
import ReceivePrintOrder from "../pages/PrintPurchaseOrder/ReceivePrintOrder";
import SendPrintOrder from "../pages/PrintPurchaseOrder/SendPrintOrder";
import CreateNewJobWork from "../pages/Out/CreateJobWork";
import ReceiveJobWork from "../pages/In/ReceiveJobWork";
import ReceiveEmbroidery from "../pages/In/ReceiveEmbroidery";
import ReceiveProductPurchaseOrder from "../pages/In/ReceiveProductPurchaseOrder";
import ViewNewPurchaseOrder from "../pages/PurchaseOrders/ViewNewPurchaseOrder";
import CreatePurchaseOrder from "../pages/PurchaseOrders/CreatePurchaseOrder";
import ViewPurchaseOrder from "../pages/Grey/ViewPurchaseOrder";
import ReceivePurchaseOrder from "../pages/Grey/ReceivePurchaseOrder";
import GreyPurchaseOrder from "../pages/Grey/GreyPurchaseOrder";
import ViewEmbroidery from "../pages/Embroidery/ViewEmbroidery";
import CreateEmbroidery from "../pages/Embroidery/CreateEmbroidery";
import CreateCategory from "../pages/Category/CreateCategory";
import ViewJobWork from "../pages/JobWork/ViewJobWork";
import CreateJobWork from "../pages/JobWork/CreateJobWork";
import UploadProduct from "../pages/Product/UploadProduct";
import CreateProduct from "../pages/Product/CreateProduct";
import UploadCatalog from "../pages/Catalog/UploadCatalog";
import CreateCatalog from "../pages/Catalog/CreateCatalog";
import ViewGreyEmbroidery from "../pages/GreyEmbroidery/ViewGreyEmbroidery";
import ReceiveGreyEmbroidery from "../pages/GreyEmbroidery/ReceiveGreyEmbroidery";
import CreateGreyEmbroidery from "../pages/GreyEmbroidery/CreateGreyEmbroidery";
import ViewGreyColor from "../pages/Grey/ViewGreyColor";
import TransferToDamage from "../pages/PurchaseOrders/TransferToDamage";
import MediaLibrary from "../pages/MediaLibrary/MediaLibrary";
import ReturnDamage from "../pages/PurchaseOrders/ReturnDamage";
import ViewTransferToDamage from "../pages/PurchaseOrders/ViewTransferToDamage";

export const routes = [
  {
    route: "/addEmployee",
    element: <AddEmployee />,
    allowedRoles: ["admin"],
  },
  {
    route: "/addVendor",
    element: <AddVendor />,
    allowedRoles: ["admin"],
  },
  {
    route: "/directPurchaseOrder",
    element: <DirectPurchaseOrder />,
    allowedRoles: ["admin"],
  },
  {
    route: "/direct/finishedOrder",
    element: <DirectFinishedOrder />,
    allowedRoles: ["admin"],
  },
  {
    route: "/direct/unfinishedOrder",
    element: <DirectUnfinishedOrder />,
    allowedRoles: ["admin"],
  },
  {
    route: "/direct/unfinishedEmbroidery",
    element: <DirectUnfinishedEmbroidery />,
    allowedRoles: ["admin"],
  },
  {
    route: "/direct/viewPO",
    element: <ViewDirectProductPO />,
    allowedRoles: ["admin"],
  },
  {
    route: "/createStockAdjustment",
    element: <StockAdjustment />,
    allowedRoles: ["admin"],
  },
  {
    route: "/viewStockAdjustment",
    element: <ViewStockAdjustment />,
    allowedRoles: ["admin"],
  },
  {
    route: "/saleReport",
    element: <SalesReport />,
    allowedRoles: ["admin"],
  },
  {
    route: "/returnReport",
    element: <ReturnReport />,
    allowedRoles: ["admin"],
  },
  {
    route: "/creditReport",
    element: <CreditReport />,
    allowedRoles: ["admin"],
  },
  {
    route: "/cashReport",
    element: <CashReport />,
    allowedRoles: ["admin"],
  },
  {
    route: "/product-saleReport",
    element: <ProductSalesReport />,
    allowedRoles: ["admin"],
  },
  {
    route: "/addLocation",
    element: <AddLocation />,
    allowedRoles: ["admin"],
  },
  {
    route: "/",
    element: <Dashboard />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/stockTransfer",
    element: <StockTransfer />,
    allowedRoles: ADMIN_ROLES.TRANSFER,
  },
  {
    route: "/view/stockTransfer",
    element: <ViewStockTransfer />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/view/out/stockTransfer",
    element: <ViewOutStockTransfer />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/assignLocation",
    element: <AssignLocation />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/in/return",
    element: <Return />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/in/returnDamage",
    element: <Return />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewCatalog",
    element: <ViewCatalog />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewProduct/:id",
    element: <ViewProduct />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/view-stock/:lid/:catalogid",
    element: <CatalogStock />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/addCashbook",
    element: <CashBook />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/addExpenseCategory",
    element: <ExpenseCategory />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewLocation",
    element: <ViewLocation />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/addCustomer",
    element: <AddCustomer />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewVendors",
    element: <ViewVendors />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewCustomer",
    element: <ViewCustomers />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/customer/:customerId",
    element: <CustomerDetail />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/editUser",
    element: <EditUser />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/sale",
    element: <Sale />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/sale/view",
    element: <ViewSale />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/generateBarcode",
    element: <GenBarcode />,
    allowedRoles: ADMIN_ROLES.MANAGEMENT,
  },
  {
    route: "/viewCashbook",
    element: <ViewCashbook />,
    allowedRoles: ["admin", "store_manager"],
  },
  {
    route: "/viewBankbook",
    element: <ViewBankBook />,
    allowedRoles: ["admin", "store_manager"],
  },
  {
    route: "/lowStock",
    element: <LowStock />,
    allowedRoles: ["admin", "store_manager"],
  },
  {
    route: "/lowStock",
    element: <IncompletePurchase />,
    allowedRoles: ["admin", "store_manager"],
  },
  {
    route: "/greyInventory/:unit",
    element: <InventoryFilter />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/unfinishedTransfer",
    element: <UnfinishedTransfer />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/transferToDamage",
    element: <TransferToDamage />,
    allowedRoles: ["admin"],
  },
  {
    route: "/view/transferToDamage",
    element: <ViewTransferToDamage />,
    allowedRoles: ["admin"],
  },
  {
    route: "/returnDamage",
    element: <ReturnDamage />,
    allowedRoles: ["admin"],
  },
  {
    route: "/viewUnfinished",
    element: <ViewUnfinishedTransfer />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/greyInventory/internal/:quality/:location",
    element: <GreyInventoryInternal />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/addGrey",
    element: <AddGrey />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/addGreyColor",
    element: <AddGreyColor />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewGreyColor/:id",
    element: <ViewGreyColor />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/greyEmbroidery",
    element: <CreateGreyEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/receive/greyEmbroidery",
    element: <ReceiveGreyEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewGreyEmbroidery",
    element: <ViewGreyEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createCatalog",
    element: <CreateCatalog />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/uploadCatalog",
    element: <UploadCatalog />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createProduct",
    element: <CreateProduct />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/uploadProduct",
    element: <UploadProduct />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createJobWork",
    element: <CreateJobWork />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewJobWorkOrders",
    element: <ViewJobWork />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createCatogory",
    element: <CreateCategory />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createEmbroidery",
    element: <CreateEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/ViewEmbroidery",
    element: <ViewEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createPurchaseOrder",
    element: <GreyPurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/receivePurchaseOrder",
    element: <ReceivePurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewPurchaseOrder",
    element: <ViewPurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/createNewPurchaseOrder",
    element: <CreatePurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewNewPurchaseOrder",
    element: <ViewNewPurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/in/receivePurchaseOrder",
    element: <ReceiveProductPurchaseOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/in/receiveEmbroideryOrder",
    element: <ReceiveEmbroidery />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/in/receiveJobOrder",
    element: <ReceiveJobWork />,
    allowedRoles: ["admin", "godown_manager", "store_manager"],
  },
  {
    route: "/out/createjobWork",
    element: <CreateNewJobWork />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/sendOrder",
    element: <SendPrintOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/sendOrder/:id",
    element: <SendPrintOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/receiveOrder",
    element: <ReceivePrintOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/viewOrder",
    element: <ViewPrintOrder />,
    allowedRoles: ["admin", "godown_manager"],
  },
  {
    route: "/mediaLibrary",
    element: <MediaLibrary />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
  {
    route: "/mediaLibrary/*",
    element: <MediaLibrary />,
    allowedRoles: ADMIN_ROLES.EMPLOYEE,
  },
];
