import React, { useState, useEffect, useRef } from "react";
import {
  Archive,
  Briefcase,
  Database,
  Edit,
  Mail,
  User,
  UserPlus,
  Users,
  Box,
  ExternalLink,
  Clipboard,
  Download,
  Trello,
  FolderPlus,
  Monitor,
  MapPin,
  Home,
  Book,
  Upload,
  DollarSign,
  Tag,
  Bell,
  ShoppingBag,
  BarChart2,
} from "react-feather";
import { NavLink } from "react-router-dom";
import SidebarGroup from "../../components/infrastructure/SidebarGroup";
import SidebarSingle from "../../components/infrastructure/SidebarSingle";
import SidebarTopText from "../../components/infrastructure/SidebarTopText";
import { Send } from "react-feather";
import { Command } from "react-feather";
import ADMIN_ROLES from "../../utils/roles";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    console.log("done");
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            {/* <div className="flex items-center">
              <img
                className="w-full sidebar-expanded:w-1/3 "
                src="https://res.cloudinary.com/blinklink-solutions/image/upload/v1562090581/60292_cr_cr_bmfn3c.png"
              />
              <p className="hidden sidebar-expanded:block text-base  text-white">
                Blinklink Solutions
              </p>
            </div> */}
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <ul className="mt-3">
              {/* Dashboard */}
              <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
              <SidebarSingle
                allowedRoles={[...ADMIN_ROLES["ALL"], "stock_transfer"]}
                activePath="/"
                name="Dashboard"
                Icon={<Monitor />}
                sidebarOpen={sidebarOpen}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Add Employee",
                    link: "/addEmployee",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Add Vendor",
                    link: "/addVendor",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Add Customer",
                    link: "/addCustomer",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "View Customer",
                    link: "/viewCustomer",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "View Vendors / Employees",
                    link: "/viewVendors",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "Edit User",
                    link: "/editUser",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                ]}
                heading="User"
                Icon={<UserPlus />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarSingle
                allowedRoles={ADMIN_ROLES["ALL"]}
                activePath="/generateBarcode"
                name="Generate Barcode"
                Icon={<Tag />}
                sidebarOpen={sidebarOpen}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Sale",
                    link: "/sale",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "View Sale",
                    link: "/sale/view",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                ]}
                Icon={<ShoppingBag />}
                heading="Sale"
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Create Grey Quality",
                    link: "/addGrey",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Grey Color",
                    link: "/addGreyColor",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Purchase Order",
                    link: "/createPurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Direct Purchase Order",
                    link: "/directPurchaseOrder",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Receive Purchase Order",
                    link: "/receivePurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Purchase Order",
                    link: "/viewPurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Grey Embroidery",
                    link: "/greyEmbroidery",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Receive Grey Embroidery",
                    link: "/receive/greyEmbroidery",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Grey Embroidery",
                    link: "/ViewGreyEmbroidery",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Grey"
                Icon={<Database />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Send Print Order",
                    link: "/sendOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Receive Print Order",
                    link: "/receiveOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Print Order",
                    link: "/viewOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Print Order"
                Icon={<Command />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Receive Purchase Order",
                    link: "/in/receivePurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Receive Embroidery Order",
                    link: "/in/receiveEmbroideryOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Receive Job Order",
                    link: "/in/receiveJobOrder",
                    allowedRoles: ["godown_manager", "admin", "store_manager"],
                  },
                  {
                    label: "Return Barcode",
                    link: "/in/return",
                    allowedRoles: ["godown_manager", "admin", "store_manager"],
                  },
                  {
                    label: "Return Damage",
                    link: "/in/returnDamage",
                    allowedRoles: ["godown_manager", "admin", "store_manager"],
                  },
                ]}
                heading="In"
                Icon={<Download />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Create job work",
                    link: "/out/createJobWork",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Job Works",
                    link: "/viewJobWorkOrders",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Out"
                Icon={<Upload />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Stock Transfer",
                    link: "/stockTransfer",
                    allowedRoles: [
                      "store_manager",
                      "admin",
                      "godown_manager",
                      "stock_transfer",
                    ],
                  },
                  {
                    label: "View In Stock Transfer",
                    link: "/view/stockTransfer",
                    allowedRoles: [
                      "store_manager",
                      "admin",
                      "stock_transfer",
                      "godown_manager",
                    ],
                  },
                  {
                    label: "View Out Stock Transfer",
                    link: "/view/out/stockTransfer",
                    allowedRoles: [
                      "store_manager",
                      "admin",
                      "stock_transfer",
                      "godown_manager",
                    ],
                  },
                ]}
                heading="Stock Transfer"
                Icon={<Send />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Low Stock",
                    link: "/lowStock",
                    allowedRoles: ["store_manager", "admin"],
                  },
                  {
                    label: "Incomplete Purchase",
                    link: "/incompletePurchase",
                    allowedRoles: ["store_manager", "admin"],
                  },
                ]}
                heading="Notifications"
                Icon={<Bell />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Create Purchase Order",
                    link: "/createNewPurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Purchase Order",
                    link: "/viewNewPurchaseOrder",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Embroidery",
                    link: "/createEmbroidery",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Embroidery",
                    link: "/viewEmbroidery",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Unfinished To Damage",
                    link: "/unfinishedTransfer",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Unfinished to Damage",
                    link: "/viewUnfinished",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Transfer To Damage",
                    link: "/transferToDamage",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Transfer To Damage",
                    link: "/view/transferToDamage",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Return Damage",
                    link: "/returnDamage",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Purchase Orders"
                Icon={<Clipboard />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Direct Finished Order",
                    link: "/direct/finishedOrder",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Direct UnFinished Order",
                    link: "/direct/unfinishedOrder",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Direct UnFinished Embroidery",
                    link: "/direct/unfinishedEmbroidery",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Stock Adjustment",
                    link: "/createStockAdjustment",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "View Stock Adjustment",
                    link: "/viewStockAdjustment",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "View Direct Product PO",
                    link: "/direct/viewPO",
                    allowedRoles: ["admin"],
                  },
                ]}
                heading="Direct Stock"
                Icon={<ExternalLink />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarGroup
                links={[
                  {
                    label: "Create Catalog",
                    link: "/createCatalog",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "View Catalog",
                    link: "/viewCatalog",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Upload Old Catalog",
                    link: "/uploadCatalog",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Product",
                    link: "/createProduct",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Upload Old Product",
                    link: "/uploadProduct",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                  {
                    label: "Create Job Work Name",
                    link: "/createJobWork",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Catalog"
                Icon={<Book />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Add Expense",
                    link: "/addCashbook",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "Add Expense Category",
                    link: "/addExpenseCategory",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "View Cashbook",
                    link: "/viewCashbook",
                    allowedRoles: ["admin", "store_manager"],
                  },
                ]}
                heading="Cashbook"
                Icon={<DollarSign />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Create Category",
                    link: "/createCategory",
                    allowedRoles: ["godown_manager", "admin"],
                  },
                ]}
                heading="Category"
                Icon={<Trello />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Sales Report",
                    link: "/saleReport",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Return Report",
                    link: "/returnReport",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Credit Report",
                    link: "/creditReport",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Cash Report",
                    link: "/cashReport",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "Product Sales Report",
                    link: "/product-saleReport",
                    allowedRoles: ["admin"],
                  },
                ]}
                Icon={<BarChart2 />}
                heading="Reports"
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
              <SidebarGroup
                links={[
                  {
                    label: "Add Location",
                    link: "/addLocation",
                    allowedRoles: ["admin"],
                  },
                  {
                    label: "View Location",
                    link: "/viewLocation",
                    allowedRoles: ADMIN_ROLES["ALL"],
                  },
                  {
                    label: "Assign Location",
                    link: "/assignLocation",
                    allowedRoles: [...ADMIN_ROLES["ALL"], "stock_transfer"],
                  },
                ]}
                heading="Locations"
                Icon={<MapPin />}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />{" "}
              <SidebarSingle
                allowedRoles={ADMIN_ROLES.EMPLOYEE}
                activePath="/mediaLibrary"
                name="Media Library"
                Icon={<Briefcase />}
                sidebarOpen={sidebarOpen}
              />
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
